@tailwind components;
@tailwind utilities;

/* * {
  font-family: "CoHeadlineTrial-Regular", "CoHeadlineTrial-Light", "CoHeadlineTrial-Light", "CoHeadlineTrial-Bold",
    -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}
*/

html,
body,
#root {
  height: 100%;
  background-color: #ecf0f5;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App-map {
  height: 100vh;
  width: 100%;
}
.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: #262626;
  font-weight: 500;
  background: white;
  border: 1px solid #262626;
}

.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
}

@media print {
  .printArea {
    display: block;
  }
}
.printArea {
  display: none;
}
.react-toast-notifications__container {
  z-index: 1301 !important;
}
.pac-container {
  z-index: 100000 !important;
}
html[dir="rtl"] .mobile-phone-rtl-fix {
  unicode-bidi: embed;
  direction: ltr;
  text-align: right;
}

@import url("../src/fonts/style.css");
