/* @font-face {
  font-family: "Airbnb Cereal App Book";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Book"), url("AirbnbCerealBook.woff") format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Light";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Light"), url("AirbnbCerealLight.woff") format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Medium"), url("AirbnbCerealMedium.woff") format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Bold"), url("AirbnbCerealBold.woff") format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Extra Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Extra Bold"), url("AirbnbCerealExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Airbnb Cereal App Black";
  font-style: normal;
  font-weight: normal;
  src: local("Airbnb Cereal App Black"), url("AirbnbCerealBlack.woff") format("woff");
} */

@font-face {
  font-family: "Arab Kufi Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Arab Kufi Regular"), url("./ArbFontsArabicKufiRegular.ttf") format("truetype");
}

@font-face {
  font-family: "Arab Kufi Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Arab Kufi Medium"), url("./ArbFontsArabicKufiMedium.ttf") format("truetype");
}

@font-face {
  font-family: "Arab Kufi Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Arab Kufi Bold"), url("./ArbFontsArabicKufiBold.ttf") format("truetype");
}
@font-face {
  font-family: "CoHeadlineTrial-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("CoHeadlineTrial-Bold"), url("./CoHeadlineTrial-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "CoHeadlineTrial-Light";
  font-style: normal;
  font-weight: normal;
  src: local("CoHeadlineTrial-Light"), url("./CoHeadlineTrial-Light.ttf") format("truetype");
}
@font-face {
  font-family: "CoHeadlineTrial-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("CoHeadlineTrial-Regular"), url("./CoHeadlineTrial-Regular.ttf") format("truetype");
}
